import $ from 'jquery';
var initial;
var x = 0,y = 0;
var lastScrolled = 0;
function light(){
    const block = $(`#hex_i`);
    block.attr('style', 'left:'+x+`px;top:`+y+`px`);
    block.addClass('active');
    clearTimeout( initial );
    initial = window.setTimeout(
        function() {
            block.removeClass('active');
        }, 200);
}
$(window).on('mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave',function( event ) {
    x = event.pageX;
    y = event.pageY;
    light();
});
$(window).on('touchmove touchstart touchend touchcancel',function( e ) {
    var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
    var touch = evt.touches[0] || evt.changedTouches[0];
    x = touch.pageX;
    y = touch.pageY;
    light();
});
$(window).scroll(function( event ) {
    if (lastScrolled !== $(window).scrollTop()) {
        y -= lastScrolled;
        lastScrolled = $(window).scrollTop();
        y += lastScrolled;
    }
    light();
});

/*
$( window ).resize(function() {
    var w = $( document ).width();
    var h = $( document ).height();
    var sum = Math.floor(w/100)*Math.floor(h/50);
    $('#hex__block').show().html('<i></i>');
    for (let i = 0; i < sum; i++) {
        //$('#hex__block').append('<div class="hex"></div>');
    }
});
$(window).on('load', function() {
    $( window ).resize();
});
*/