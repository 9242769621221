import React, {useEffect, useState} from 'react'
import {ethers} from 'ethers'
import {Button } from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";


import CyberBoxes from './artifacts/contracts/CyberBoxes.sol/CyberBoxes.json'
import CyberMarketPlace from './artifacts/contracts/CyberMarketPlace.sol/CyberMarketPlace.json'
import './App.scss';

const lockAddress = "0x11180F73cD7FF135De69446031d6DE68c00C1C59"//"0x5fbdb2315678afecb367f032d93f642f64180aa3"
function App() {
    library.add(fab,far)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalData, setModalData] = useState([]);

    /*
    async function setGreeting(value){
      if (!value) return;
      if(!typeof window.ethereum !== 'undefined'){
        await requestAccount()
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const contract = new ethers.Contract(lockAddress, Lock.abi, provider)
        const transaction = await contract.setGreeting(value)
        await transaction.wait()
        fetchGreeting()
      }
    }

     */

    const [walletAdress, setWalletAdress] = useState("");

    async function requestAccount() {
        console.log("first");
        if (window.ethereum) {
            console.log('detected');
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setWalletAdress(accounts);
            } catch (error) {
                console.log('Error connecting...')
            }
        } else {
            alert('Meta Mask not detected');

        }
    }

    const [dataTest, setDataTest] = useState("")



    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('loading');
    const [error, setError] = useState(null);
    const fetchCollection = async () => {
        //const provider = new ethers.providers.Web3Provider(window.ethereum)
        const provider = await new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545');
        const contractCyberBoxes = await new ethers.Contract(lockAddress, CyberBoxes.abi, provider)
        const boxes = []
        for (let i = 0; i < await contractCyberBoxes.boxTypes(); i++) {
            let img = '';
            let img2 = '';
            let img3 = '';
            let name = '';
            switch (i) {
                case 0:
                    img = require("./assets/img/box/BOX_level_01_crop.png");
                    img2 = require("./assets/img/box/L1/Buy/BOX_level_01.webm");
                    img3 = require("./assets/img/box/L1/idle/BOX_level_01.webm");
                    name = 'BOX_Common'
                    break;
                case 1:
                    img = require("./assets/img/box/BOX_level_02_crop.png");
                    img2 = require("./assets/img/box/L2/Buy/BOX_level_02.webm");
                    name = 'BOX_Uncommon'
                    break;
                case 2:
                    img = require("./assets/img/box/BOX_level_03_crop.png");
                    img2 = require("./assets/img/box/L3/BYE/BOX_level_03.webm");
                    name = 'BOX_Rare'
                    break;
                case 3:
                    img = require("./assets/img/box/BOX_level_04_crop.png");
                    img2 = require("./assets/img/box/L4/Buy/BOX_level_04.webm");
                    name = 'BOX_Epic'
                    break;
                case 4:
                    img = require("./assets/img/box/BOX_level_05_crop.png");
                    img2 = require("./assets/img/box/L5/Bye/BOX_level_05.webm");
                    name = 'BOX_Legendary'
                    break;
            }
            boxes.push({id: i, name: name, price: await contractCyberBoxes.boxPrices(i), img: img, img2: img2, img3: img3})
        }
        return boxes
    }
    useEffect(() => {
        if(!window.ethereum){
            console.log(`listening for Transfer...`)
        }
        fetchCollection()
            .then((response)=> {
                setData(response);
                setLoading('')
            })

    }, []);
    return (
        <div className={"App "+loading}>
            <i className="loading__back"><img src={require("./assets/img/hex_loading.webp")} alt={"loading"}/></i>
            <section className="container">

                <div className={"text-center mb-5"}>
                    <img src={require("./assets/img/logo.png")} alt=""/>
                    <div className={"f20 color-dark mt-4"}>
                        NFT Marketplace_
                    </div>
                    <div className={"color-orange"}>
                        Craft and trade_
                    </div>
                    <div className={"my-4"}>
                        <button className={"button_hex button_hex--create m-3"}></button>
                        <button className={"button_hex button_hex--explore m-3"}></button>
                        <button className={"button_hex button_hex--connect m-3"} onClick={requestAccount}></button>
                    </div>
                    <h5 className={"f14"}>Wallet Address: {walletAdress}</h5>
                </div>
                <div className="row justify-content-around">
                    {data.map((box, index) => {
                        return (
                            <div className="col-md-6 col-lg-4 mb-4" key={index}>
                                <div className={"frame_block"}
                                     onClick={()=> {
                                         setModalData(box);
                                         setShow(true);
                                     }}
                                >
                                    <div className={"frame_block__bg"}>
                                        <div className={"frame_block__bg--text"}>CyberDAO.Cell.NFT_</div>
                                        <img src={box.img} alt=""/>
                                    </div>
                                    <div className={"frame_block__bottom"}>
                                        <h5>{box.name}_</h5>
                                        <button className="frame_block__buy-btn"></button>
                                        <div className={"frame_block__price"}>USDT {box.price}_</div>
                                    </div>
                                </div>
                            </div>

                        );
                    })}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        size="lg"
                        centered
                    >
                        <div className={"container"}>
                            <Button className={"btn-close2"} onClick={handleClose}>
                                <FontAwesomeIcon icon={['far', 'circle-xmark']} />
                            </Button>
                            <div className={"row align-middle py-4"}>
                                <div className={"col-lg-7"}>
                                    <video autoPlay muted playsInline loop>
                                        <source src={modalData.img2} type='video/webm;'/>
                                    </video>
                                    <div className={"d-flex align-items-center position-relative"}>
                                        <div>
                                            <button className="button button--top-center mx-3">OPEN</button>
                                        </div>
                                        <div className={"ms-auto d-flex align-items-center"}>
                                            <div className={"me-3"}>USDT {modalData.price}_</div>
                                            <button className="button button--top-center">BUY_NOW</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-lg-5 position-relative"}>
                                    <div className={"f14 color-dark"}>#{modalData.id}</div>
                                    <h3>{modalData.name}_</h3>
                                    <div className={"f14"}>
                                        Common_:
                                        <div className={"icons icons--Common"}>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                        </div>
                                        Uncommon_:
                                        <div className={"icons icons--Uncommon"}>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                        </div>
                                        Rare_:
                                        <div className={"icons icons--Rare"}>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                        </div>
                                        Epic_:
                                        <div className={"icons icons--Epic"}>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                        </div>
                                        Legendary_:
                                        <div className={"icons icons--Legendary"}>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                            <img src={require("./assets/img/ico_bee.png")} alt={""}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
            </section>
            <section className="container">
                <div className={"text-center pb-5"}>
                    <img src={require("./assets/img/logo.png")} height={"120"} alt=""/>
                    <div className="social">
                        <a href="https://twitter.com/cybermetafarm?s=21&t=75Pm6TFOv7aGLLSHIB-enw" target="_blank">
                            <FontAwesomeIcon icon={['fab', 'twitter']} />
                        </a>
                        <a href="https://t.me/CyberMetaFarm" target="_blank">
                            <FontAwesomeIcon icon={['fab', 'telegram']} />
                        </a>
                        <a href="https://discord.gg/3N68nRCE69" target="_blank">
                            <FontAwesomeIcon icon={['fab', 'discord']} />
                        </a>
                    </div>
                    <div className={"f12 color-orange mt-4"}>
                        <b>Copyright © 2022-2023 Cyber DAO_ All rights reserved.</b>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default App;
